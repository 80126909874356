<template>
  <v-card
    flat
  >
    <v-row>
      <v-col>
        <v-expand-transition>
          <v-card
            flat
            class="ml-1 mt-4"
            width="650"
          >
            <v-card-text
              class="py-4"
            >
              <v-menu
                :close-on-content-click="true"
                :nudge-width="200"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    outlined
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ documentSections[selectedDocumentSection] }}
                    <v-icon
                      small
                      right
                    >
                      mdi-menu-down
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item-group
                    v-model="selectedDocumentSection"
                    color="primary"
                  >
                    <v-list-item
                      v-for="(item, index) in documentSections"
                      :key="index"
                    >
                      <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </v-card-text>
            <v-divider />
            <v-card
              flat
              class="scroll"
              height="300"
              width="650"
            >
              <v-list>
                <v-list-item-group>
                  <template v-for="doc in filteredDocuments">
                    <v-list-item
                      :key="doc._id"
                      :value="doc"
                      active-class="blue--text text--accent-4"
                      @click="selectDoc(doc)"
                    >
                      <template>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ doc.raw.originalname }}
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon
                            color="primary accent-4"
                          >
                            mdi-chevron-right
                          </v-icon>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-card>
        </v-expand-transition>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import generalMixin from '@/utils/generalMixin.js'

export default {
  mixins: [generalMixin],
  props: {
    cancelSelection: {
      type: Boolean,
      default: undefined
    },
    incommingItem: {
      type: [Object, String],
      default: null
    },
    selectionTarget: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      document: null,
      selected: [],
      selectedDocumentSection: 0
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company,
      clients: state => state.clients.clients,
      documents: state => state.documents.documents,
      companyDocuments: state => state.companyDocuments.companyDocuments,
      groupDocuments: state => state.groupDocuments.groupDocuments
    }),
    filteredDocuments () {
      let documents
      if (this.documentSections[this.selectedDocumentSection] === this.$t('documents|my_documents')) {
        documents = this.documents
      }
      if (this.company && this.documentSections[this.selectedDocumentSection] === this.company.companyData.companyName) {
        documents = this.companyDocuments
      }
      if (this.company && this.documentSections[this.selectedDocumentSection] !== this.$t('documents|my_documents') && this.documentSections[this.selectedDocumentSection] !== this.company.companyData.companyName) {
        let documents = []
        for (let i = 0; i < this.company.groups.length; i++) {
          const group = this.company.groups[i]
          if (group.groupName === this.documentSections[this.selectedDocumentSection]) {
            this.groupDocuments.forEach(doc => {
              const sharedDoc = doc.sharedWith.groups.find(g => g._id === group._id)
              if (sharedDoc) {
                documents.push(doc)
              }
            })
          }
        }
        return documents
      }
      return documents
    },
    documentSections () {
      let myAvailableSections = []
      if (this.account && !this.account.companyId) {
        myAvailableSections.push(this.$t('documents|my_documents'))
      }
      if (this.account && this.account.companyId && this.company) {
        const userRole = this.verifyUserRole(this.company, this.account)
        // verifyUserRole could be found in generalMixin
        if (userRole === 'regular') {
          myAvailableSections.push(this.company.companyData.companyName)
          for (let i = 0; i < this.company.groups.length; i++) {
            const group = this.company.groups[i]
            const participation = group.groupMembers.find(m => m._id === this.account._id)
            if (participation) {
              myAvailableSections.push(group.groupName)
            }
          }
        } else {
          myAvailableSections.push(this.$t('documents|my_documents'))
          myAvailableSections.push(this.company.companyData.companyName)
          for (let i = 0; i < this.company.groups.length; i++) {
            const group = this.company.groups[i]
            const participation = group.groupMembers.find(m => m._id === this.account._id)
            if (participation) {
              myAvailableSections.push(group.groupName)
            }
          }
        }
      }
      return myAvailableSections
    }
  },
  watch: {
    cancelSelection (value) {
      if (value === true) {
        this.selected.splice(0, 1)
        this.$emit('docForTemplateWasDeleted', this.selected)
      }
    },
    // selectionTarget watcher has to update the v-model index of the menu
    // and pre select the documents to match company/group/my docs
    // it is just a preset, the user can select different setting from the menu
    selectionTarget (value) {
      if (value) {
        // if the addTemplate dialog was opened from the dashboard
        // the availableSections computed function adds 'templates'
        // at the end of the string, so we need to slice the string first
        // to get the needed result
        const result = this.documentSections.findIndex(element => {
          if (element.includes(value)) {
            return true
          } else {
            let arr = value.split(' ') // slicing 'templates' from string to match
            arr.pop()
            const newString = arr.join(' ')
            if (element.includes(newString)) {
              return true
            }
          }
        })
        if (result !== -1) {
          this.selectedDocumentSection = result
        }
      }
      // here we are listening for selectionTarget that is comming
      // when the dialog has been opened from the template section
      // so we have clean string with no 'templates in it'
      const match = this.documentSections.findIndex(section => {
        if (section.includes(value)) {
          return true
        }
      })

      if (match !== -1) {
        this.selectedDocumentSection = match
      }
    }
  },
  methods: {
    selectDoc (doc) {
      this.selected.push(doc)
      this.$emit('docForTemplateWasSelected', this.selected)
      this.selected = []
    }
  }
}
</script>

<style scoped>
.scroll {
   overflow-y: auto
}
</style>
