<template>
  <v-card
    flat
  >
    <v-row>
      <v-col>
        <v-expand-transition>
          <v-card
            flat
            class="ml-1 mt-4"
            max-width="600"
          >
            <v-card-text
              class="py-4"
            >
              <v-menu
                :close-on-content-click="true"
                :nudge-width="200"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    outlined
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ templateSections[selectedTemplateSection] }}
                    <v-icon
                      small
                      right
                    >
                      mdi-menu-down
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item-group
                    v-model="selectedTemplateSection"
                    color="primary"
                  >
                    <v-list-item
                      v-for="(item, index) in templateSections"
                      :key="index"
                    >
                      <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </v-card-text>
            <v-divider />
            <v-card
              flat
              class="scroll"
              height="300"
              width="600"
            >
              <v-list>
                <v-list-item-group>
                  <template v-for="(template, index) in filteredTemplates">
                    <v-list-item
                      :key="index"
                      :value="template"
                      active-class="blue--text text--accent-4"
                      @click="selectTemp(template)"
                    >
                      <template>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ template.name }}
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-icon
                            color="primary accent-4"
                          >
                            mdi-chevron-right
                          </v-icon>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-card>
        </v-expand-transition>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import generalMixin from '@/utils/generalMixin.js'

export default {
  mixins: [generalMixin],
  props: {
    cancelSelection: {
      type: Boolean,
      default: false
    },
    incommingItem: {
      type: [Object, String],
      default: null
    },
    selectionTarget: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      document: null,
      selected: [],
      selectedTemplateSection: 0
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company,
      clients: state => state.clients.clients,
      templates: state => state.templates.templates,
      companyTemplates: state => state.companyTemplates.companyTemplates,
      groupTemplates: state => state.groupTemplates.groupTemplates
    }),
    filteredTemplates () {
      let templates
      if (this.templateSections[this.selectedTemplateSection] === this.$t('templates|my_templates')) {
        templates = this.templates
      }
      if (this.company && this.templateSections[this.selectedTemplateSection] === this.company.companyData.companyName) {
        templates = this.companyTemplates
      }
      if (this.company && this.templateSections[this.selectedTemplateSection] !== this.$t('templates|my_templates') && this.templateSections[this.selectedTemplateSection] !== this.company.companyData.companyName) {
        let templates = []
        for (let i = 0; i < this.company.groups.length; i++) {
          const group = this.company.groups[i]
          if (group.groupName === this.templateSections[this.selectedTemplateSection]) {
            this.groupTemplates.forEach(temp => {
              const sharedTemp = temp.sharedWith.groups.find(g => g._id === group._id)
              if (sharedTemp) {
                templates.push(temp)
              }
            })
          }
        }
        return templates
      }
      return templates
    },
    templateSections () {
      let myAvailableSections = []
      if (this.account && !this.account.companyId) {
        myAvailableSections.push(this.$t('templates|my_templates'))
      }
      if (this.account && this.account.companyId && this.company) {
        const userRole = this.verifyUserRole(this.company, this.account)
        // verifyUserRole could be found in generalMixin
        if (userRole === 'regular') {
          myAvailableSections.push(this.company.companyData.companyName)
          for (let i = 0; i < this.company.groups.length; i++) {
            const group = this.company.groups[i]
            const participation = group.groupMembers.find(m => m._id === this.account._id)
            if (participation) {
              myAvailableSections.push(group.groupName)
            }
          }
        } else {
          myAvailableSections.push(this.$t('templates|my_templates'))
          myAvailableSections.push(this.company.companyData.companyName)
          for (let i = 0; i < this.company.groups.length; i++) {
            const group = this.company.groups[i]
            const participation = group.groupMembers.find(m => m._id === this.account._id)
            if (participation) {
              myAvailableSections.push(group.groupName)
            }
          }
        }
      }
      return myAvailableSections
    }
  },
  watch: {
    selected (selected) {
      if (selected) {
        this.$emit('tempForTemplateWasSelected', selected)
      }
    },
    cancelSelection (value) {
      if (value === true) {
        this.selected.splice(0, 1)
        this.$emit('tempForTemplateWasDeleted', this.selected)
        this.selected = []
      }
    },
    // selectionTarger watcher has to update the v-model index of the menu
    // and pre select the templates to match company/group/my docs
    // it is just a preset, the user can select different setting from the menu
    selectionTarget (value) {
      if (value) {
        // if the addTemplate dialog was opened from the dashboard
        // the availableSections computed function adds 'templates'
        // at the end of the string, so we need to slice the string first
        // to get the needed result
        const result = this.templateSections.findIndex(element => {
          if (element.includes(value)) {
            return true
          } else {
            let arr = value.split(' ') // slicing 'templates' from string to match
            arr.pop()
            const newString = arr.join(' ')
            if (element.includes(newString)) {
              return true
            }
          }
        })
        if (result !== -1) {
          this.selectedTemplateSection = result
        }
      }
      // here we are listening for selectionTarget that is comming
      // when the dialog has been opened from the template section
      // so we have clean string with no 'templates in it'
      const match = this.templateSections.findIndex(section => {
        if (section.includes(value)) {
          return true
        }
      })

      if (match !== -1) {
        this.selectedDocumentSection = match
      }
    }
  },
  methods: {
    selectTemp (temp) {
      this.selected.push(temp)
      this.$emit('tempForTemplateWasSelected', this.selected)
    }
  }
}
</script>

<style scoped>
.scroll {
   overflow-y: auto
}
</style>
