<template>
  <v-container
    fill-height
    style="background-color: white;"
    class="pa-0"
  >
    <v-card
      flat
      width="100%"
      height="100%"
    >
      <v-toolbar
        flat
        width="100%"
        class="toolbar"
      >
        <v-btn
          icon
          rounded
          @click.stop="$emit('closePage')"
        >
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
        <v-toolbar-title
          style="white-space: normal; font-size: 22px;"
        >
          {{ $t('actions|add_new_template') }}
        </v-toolbar-title>
      </v-toolbar>

      <!-- <v-toolbar
        flat
        class="add-template-toolbar mb-n6"
        width="100%"
        :rounded="false"
      >
        <v-toolbar-title>{{ $t('actions|add_new_template') }}</v-toolbar-title>
        <div
          style="position: absolute; top: 3px; right: 7px"
        >
          <v-btn
            icon
            @click="dialog = false; reset()"
            @mouseover="hoverCloseButtonIcon = true"
            @mouseleave="hoverCloseButtonIcon = false"
          >
            <v-icon
              :small="hoverCloseButtonIcon"
            >
              mdi-close
            </v-icon>
          </v-btn>
        </div>
      </v-toolbar> -->

      <v-stepper
        v-model="creationSteps"
        non-linear
        vertical
        flat
        :valid="false"
        class="mt-4"
      >
        <v-stepper-step
          :complete="creationSteps > 0"
          step=""
        >
          {{ $t('actions|add_to') }}
          <small
            v-if="creationSteps > 0"
            class="mt-2"
          >
            {{ selectionTarget }}
          </small>
        </v-stepper-step>
        <v-stepper-content step="0">
          <v-select
            v-model="selectionTarget"
            :items="availableSections"
            item-text="text"
            item-value="text"
            item-disabled="disabled"
            outlined
            dense
            :label="$t('templates|template_is_provided_for')"
            class="my-2"
          />
        </v-stepper-content>

        <v-stepper-step
          :complete="creationSteps > 1"
          step=""
        >
          {{ $t('mixed|create_from') }}
          <small
            v-if="creationSteps > 1"
            class="mt-2"
          >
            {{ selected }}
          </small>
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-radio-group v-model="selected">
            <v-row
              class="text-center"
            >
              <v-col
                cols="12"
              >
                <v-card
                  class="d-inline-flex align-center pa-2"
                  outlined
                  rounded
                  flat
                  height="40"
                  width="300"
                >
                  <div class="row align-center ml-2">
                    <v-radio
                      :label="$t('mixed|your_computer')"
                      color="primary"
                      :value="$t('mixed|your_computer')"
                      class="mt-2"
                    />
                    <v-icon
                      style="position: absolute; right: 5%;"
                      color="primary"
                      right
                    >
                      mdi-arrow-up-bold
                    </v-icon>
                  </div>
                </v-card>
                <div>
                  <span
                    class="font-weight-medium black--text"
                    style="font-size: 13px"
                  >
                    {{ $t('expressions|upload_file_from_computer') }}
                  </span>
                </div>
                <v-card
                  class="d-inline-flex align-center pa-2 mt-4"
                  outlined
                  rounded
                  flat
                  height="40"
                  width="300"
                >
                  <div class="row align-center ml-2">
                    <v-radio
                      :label="$t('documents|existing_document')"
                      color="primary"
                      :value="$t('documents|existing_document')"
                      class="mt-2"
                    />
                    <v-icon
                      style="position: absolute; right: 5%;"
                      color="primary"
                      right
                    >
                      mdi-file
                    </v-icon>
                  </div>
                </v-card>
                <div>
                  <span
                    class="font-weight-medium black--text"
                    style="font-size: 13px"
                  >
                    {{ $t('expressions|choose_from_uploaded_documents') }}
                  </span>
                </div>
                <v-card
                  class="d-inline-flex align-center pa-2 mt-4"
                  outlined
                  rounded
                  flat
                  height="40"
                  width="300"
                >
                  <div class="row align-center ml-2">
                    <v-radio
                      :label="$t('templates|other_template')"
                      color="primary"
                      :value="$t('templates|other_template')"
                      class="mt-2"
                    />
                    <v-icon
                      style="position: absolute; right: 5%;"
                      color="primary"
                      right
                    >
                      {{ icon }}
                    </v-icon>
                  </div>
                </v-card>
                <div>
                  <span
                    class="font-weight-medium black--text"
                    style="font-size: 13px"
                  >
                    {{ $t('expressions|use_already_existing_template') }}
                  </span>
                </div>
                <v-card
                  class="d-inline-flex align-center pa-2 mt-4"
                  outlined
                  rounded
                  flat
                  height="40"
                  width="300"
                >
                  <div class="row align-center ml-2">
                    <v-radio
                      :label="$t('mixed|blank')"
                      color="primary"
                      :value="$t('mixed|blank')"
                      class="mt-2"
                    />
                    <v-icon
                      style="position: absolute; right: 5%;"
                      color="primary"
                      right
                    >
                      mdi-file-outline
                    </v-icon>
                  </div>
                </v-card>
                <div>
                  <span
                    class="font-weight-medium black--text"
                    style="font-size: 13px"
                  >
                    {{ $t('expressions|create_new_blank_temlate') }}
                  </span>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="incommingItem === 'fromDashboard'">
              <v-col cols="2">
                <v-btn
                  text
                  rounded
                  class="mb-2 ml-2"
                  @click="getBack()"
                >
                  <v-icon
                    class="mr-1"
                  >
                    mdi-undo
                  </v-icon>
                  {{ $t('actions|back') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-radio-group>
        </v-stepper-content>

        <div
          v-if="creationSteps === 2 || templateDocument.length"
        >
          <v-stepper-step
            :complete="creationSteps > 2"
            step=""
          >
            {{ $t('actions|upload_document') }}
            <small
              v-if="creationSteps > 2"
              class="mt-2"
            >
              {{ $t('documents|document') }}: {{ templateDocument[0].file.name }}
            </small>
            <small
              v-if="creationSteps > 2"
              class="mt-2"
            >
              {{ $t('common|language') }}: {{ templateDocument[0].language.toString() }}
            </small>
          </v-stepper-step>
        </div>

        <v-stepper-content
          step="2"
        >
          <v-card
            height="200px"
            flat
            class="mb-6"
          >
            <file-input
              v-if="creationSteps === 2"
              v-model="templateDocument"
              :multiple="false"
              :file-rows="12"
              :in-template="true"
              :disabled-btn="templateDocument.length ? true : false"
              :show-upload-area="showArea"
            />
          </v-card>
          <v-btn
            color="primary"
            rounded
            :disabled="disableContinueButtonUploadedDoc"
            class="mb-2"
            @click="creationSteps = 5"
          >
            {{ $t('actions|continue') }}
          </v-btn>
          <v-btn
            text
            rounded
            class="mb-2 ml-2"
            @click="getBack(); templateDocument = []"
          >
            <v-icon
              class="mr-1"
            >
              mdi-undo
            </v-icon>
            {{ $t('actions|back') }}
          </v-btn>
        </v-stepper-content>

        <div
          v-if="creationSteps === 3 || selectedExistingDocument.length"
        >
          <v-stepper-step
            :complete="creationSteps > 3"
            step=""
          >
            {{ $t('actions|select_document') }}
            <small
              v-if="creationSteps > 3"
              class="mt-2"
            >
              {{ selectedExistingDocument[0].raw.originalname }}
            </small>
          </v-stepper-step>
        </div>

        <v-stepper-content
          step="3"
        >
          <v-card
            height="435px"
            flat
          >
            <ImportDocumentForTemplate
              :incomming-item="incommingItem"
              :selection-target="selectionTarget"
              :cancel-selection="cancelSelection"
              @docForTemplateWasSelected="handleIncommingDocument"
              @docForTemplateWasDeleted="handleIncommingDocument; cancelSelection = false"
            />
          </v-card>
          <!-- <v-btn
                  color="primary"
                  rounded
                  :disabled="disableContinueButtonDocs"
                  class="mb-2"
                  @click="creationSteps = 5"
                >
                  Continue
                </v-btn> -->
          <v-btn
            text
            rounded
            class="mb-2 ml-2"
            @click="getBack()"
          >
            <v-icon
              class="mr-1"
            >
              mdi-undo
            </v-icon>
            {{ $t('actions|back') }}
          </v-btn>
        </v-stepper-content>

        <div
          v-if="creationSteps === 4 || selectedExistingTemplate.length"
        >
          <v-stepper-step
            :complete="creationSteps > 4"
            step=""
          >
            {{ $t('actions|select_other_template') }}
            <small
              v-if="creationSteps > 4"
              class="mt-2"
            >
              {{ selectedExistingTemplate[0].name }}
            </small>
          </v-stepper-step>
        </div>

        <v-stepper-content
          step="4"
        >
          <v-card
            height="435px"
            flat
          >
            <ImportTemplateForTemplate
              :incomming-item="incommingItem"
              :selection-target="selectionTarget"
              :cancel-selection="cancelSelectionInTemp"
              @tempForTemplateWasSelected="handleIncommingTemplate"
            />
          </v-card>
          <!-- <v-btn
                  color="primary"
                  rounded
                  :disabled="disableContinueButtonTemplate"
                  class="mb-2"
                  @click="creationSteps = 5"
                >
                  Continue
                </v-btn> -->
          <v-btn
            text
            rounded
            class="mb-2 ml-2"
            @click="getBack()"
          >
            <v-icon
              class="mr-1"
            >
              mdi-undo
            </v-icon>
            {{ $t('actions|back') }}
          </v-btn>
        </v-stepper-content>

        <v-stepper-step
          :complete="creationSteps > 5"
          step=""
        >
          {{ $t('mixed|fill_in_the_information') }}
        </v-stepper-step>

        <v-stepper-content step="5">
          <v-card
            height="300px"
            class="pt-4"
            flat
          >
            <v-text-field
              v-model="name"
              :error-messages="nameErrors"
              :label="$t('templates|template_name')"
              required
              outlined
              dense
              clearable
              @input="$v.name.$touch()"
              @blur="$v.name.$touch()"
            />
            <!-- <v-select
                    v-model="language"
                    :items="languagesList"
                    :error-messages="languageErrors"
                    label="Suggestion and questionnaire language"
                    required
                    outlined
                    dense
                    item-text="title"
                    item-value="value"
                    @input="$v.language.$touch()"
                    @blur="$v.language.$touch()"
                  /> -->
            <v-text-field
              v-model="description"
              outlined
              clearable
              dense
              :label="$t('common|description')"
            />
            <v-select
              v-model="selectedCategory"
              outlined
              dense
              :label="$t('actions|choose_category')"
              :items="availableCategories"
              item-text="value"
              item-value="value"
            >
              <template
                slot="item"
                slot-scope="data"
              >
                <v-list
                  v-model="selectedCategory"
                >
                  <v-list-item
                    three-line
                  >
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-icon
                          x-small
                        >
                          mdi-tag-outline
                        </v-icon>
                        {{ data.item.value }}
                      </v-list-item-title>
                      <v-list-item-subtitle>{{ $t('mixed|category_created_by') }}:</v-list-item-subtitle>
                      <v-list-item-subtitle>{{ data.item.createdBy }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </template>
            </v-select>
          </v-card>
          <v-card-actions>
            <v-btn
              class="mb-2"
              rounded
              text
              @click="getBack(); resetForm()"
            >
              <v-icon
                class="mr-1"
              >
                mdi-undo
              </v-icon>
              {{ $t('actions|back') }}
            </v-btn>
            <v-spacer />
            <v-btn
              color="primary"
              rounded
              min-width="150"
              :disabled="disabled"
              :loading="loading"
              class="mb-2"
              @click="submit"
            >
              {{ $t('actions|submit') }}
            </v-btn>
          </v-card-actions>
        </v-stepper-content>
      </v-stepper>
    </v-card>
  </v-container>
</template>

<script>
import { mdiTextBoxOutline } from '@mdi/js'
import { userHasAccess } from '@/utils/utils'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { mapState, mapActions } from 'vuex'
import autobahn from 'autobahn'
import generalMixin from '@/utils/generalMixin.js'
import ImportDocumentForTemplate from '@/components/partials/ImportDocumentForTemplate.vue'
import ImportTemplateForTemplate from '@/components/partials/ImportTemplateForTemplate.vue'

export default {
  components: {
    ImportDocumentForTemplate,
    ImportTemplateForTemplate
  },
  mixins: [
    validationMixin,
    generalMixin
  ],
  props: {
    incommingItem: {
      type: Object,
      default: () => ({})
    }
  },
  validations: {
    name: { required }
  },
  data () {
    return {
      dialog: false,
      disabled: true,
      disableContinueButtonDocs: true,
      disableContinueButtonTemplate: true,
      disableContinueButtonUploadedDoc: true,
      cancelSelection: false,
      cancelSelectionInTemp: false,
      selected: null,
      creationSteps: 0,
      name: null,
      loading: false,
      error: null,
      description: '',
      templateDocument: [],
      selectedCategory: '',
      selectedExistingDocument: [],
      selectedExistingTemplate: [],
      icon: mdiTextBoxOutline,
      hoverCloseButtonIcon: false,
      fromDashboard: false,
      selectionTarget: null,
      showArea: true
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company,
      documents: state => state.documents.documents,
      companyDocuments: state => state.companyDocuments.companyDocuments,
      groupDocuments: state => state.groupDocuments.groupDocuments,
      settings: state => state.settings.settings
    }),
    nameErrors () {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      !this.$v.name.required && errors.push(this.$t('common|field_required'))
      return errors
    },
    availableCategories () {
      let categories = []
      if (this.incommingItem.accountId || this.selectionTarget === 'My templates') {
        this.account.myTemplateCategories.forEach(cat => {
          const newCat = {
            createdBy: this.account.accountName ? this.account.accountName : this.$t('mixed|account_no_longer_available'),
            value: cat.value
          }
          categories.push(newCat)
        })
      } else if (this.incommingItem.company || (this.company && this.selectionTarget && this.selectionTarget.includes(this.company.companyData.companyName))) {
        this.company.companyTemplatesCategories.forEach(cat => {
          const newCat = {
            createdBy: this.company.companyData.companyName,
            value: cat.value
          }
          categories.push(newCat)
        })
      } else if (this.incommingItem && this.incommingItem.group) {
        this.incommingItem.group.groupTemplatesCategories.forEach(cat => {
          const newCat = {
            createdBy: this.incommingItem.group.groupName,
            value: cat.value
          }
          categories.push(newCat)
        })
      } else if (this.selectionTarget && this.company && !this.selectionTarget.includes(this.company.companyData.companyName) && this.selectionTarget !== 'My templates') {
        for (let i = 0; i < this.company.groups.length; i++) {
          const group = this.company.groups[i]
          if (this.selectionTarget.includes(group.groupName)) {
            group.groupTemplatesCategories.forEach(cat => {
              const newCat = {
                createdBy: group.groupName,
                value: cat.value
              }
              categories.push(newCat)
            })
          }
        }
      } else if (this.incommingItem && this.incommingItem.isLavviraTab) {
        const filteredCategories = this.settings.lavviraSettings.lavviraTemplateCategories.map((cat) => {
          return {
            value: cat.value,
            createdBy: 'Lavvira'
          }
        })
        categories = filteredCategories
      }
      return categories
    },
    availableSections () {
      let myAvailableSections = []

      const getAccess = (_acc, _comp, _group, _tab, _from) => {
        return userHasAccess(_acc, _comp, _group, _tab, _from)
      }
      const accountAccess = getAccess(this.account, null, null, null, 'onlyAccountAccess')
      const companyAccess = getAccess(this.account, this.company, null, null, 'availableSections')

      if (this.account && !this.account.companyId) {
        myAvailableSections.push({
          text: this.$t('templates|my_templates'),
          disabled: !accountAccess
        })
      }
      if (this.account && this.account.companyId && this.company) {
        const userRole = this.verifyUserRole(this.company, this.account)
        // verifyUserRole could be found in generalMixin
        if (userRole === 'regular') {
          myAvailableSections.push({
            text: this.company.companyData.companyName + ' ' + this.$t('templates|templates'),
            disabled: !companyAccess
          })
          for (let i = 0; i < this.company.groups.length; i++) {
            const group = this.company.groups[i]
            const participation = group.groupMembers.find(m => m._id === this.account._id)
            if (participation) {
              myAvailableSections.push({
                text: group.groupName + ' ' + this.$t('templates|templates'),
                disabled: !companyAccess
              })
            }
          }
        } else {
          myAvailableSections.push({
            text: this.$t('templates|my_templates'),
            disabled: !accountAccess
          })
          myAvailableSections.push({
            text: this.company.companyData.companyName + ' ' + this.$t('templates|templates'),
            disabled: !companyAccess
          })
          for (let i = 0; i < this.company.groups.length; i++) {
            const group = this.company.groups[i]
            const participation = group.groupMembers.find(m => m._id === this.account._id)
            if (participation) {
              myAvailableSections.push({
                text: group.groupName + ' ' + this.$t('templates|templates'),
                disabled: !companyAccess
              })
            }
          }
        }
      }
      return myAvailableSections
    }
  },
  watch: {
    dialog (value) {
      if (!value) {
        this.reset()
      }
    },
    selectionTarget (value) {
      if (value) {
        this.creationSteps = 1
      }
    },
    selected (value) {
      if (value === this.$t('mixed|your_computer')) {
        this.creationSteps = 2
      }
      if (value === this.$t('documents|existing_document')) {
        this.creationSteps = 3
      }
      if (value === this.$t('templates|other_template')) {
        this.creationSteps = 4
      }
      if (value === this.$t('mixed|blank')) {
        this.creationSteps = 5
      }
    },
    creationSteps (value) {
      if (value === 1 || value === 2 || value === 3 || value === 4) this.disabled = true
    },
    name (value) {
      if (!value || !value.length) this.disabled = true
      else this.disabled = false
    },
    selectedExistingDocument (value) {
      if (value.length) {
        this.disableContinueButtonDocs = false
      } else {
        this.disableContinueButtonDocs = true
      }
    },
    selectedExistingTemplate (value) {
      if (value.length) {
        this.disableContinueButtonTemplate = false
      } else {
        this.disableContinueButtonTemplate = true
      }
    },
    templateDocument: {
      handler (value) {
        if (value && value.length) {
          this.showArea = false
        } else {
          this.showArea = true
        }
        if (value.length && value[0] && value[0].language && value[0].language.length) {
          this.disableContinueButtonUploadedDoc = false
        } else if (this.templateDocument.length === 0 || !value[0].language || !value[0].language.length) {
          this.disableContinueButtonUploadedDoc = true
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    this.onAddTemplateEvent()
  },
  methods: {
    ...mapActions({
      addTemplate: 'templates/addTemplate',
      addCompanyTemplate: 'companyTemplates/addCompanyTemplate',
      addGroupTemplate: 'groupTemplates/addGroupTemplate',
      uploadDocument: 'documents/uploadDocument',
      uploadCompanyDocument: 'companyDocuments/uploadCompanyDocument',
      uploadGroupDocument: 'groupDocuments/uploadGroupDocument',
      getDocument: 'documents/getDocument',
      setSceletonCard: 'templates/setSceletonCard',
      setCompanySceletonCard: 'companyTemplates/setCompanySceletonCard',
      setGroupSceletonCard: 'groupTemplates/setGroupSceletonCard',
      deleteSceleton: 'templates/deleteSceleton',
      deleteCompanySceleton: 'companyTemplates/deleteCompanySceleton',
      deleteGroupSceleton: 'groupTemplates/deleteGroupSceleton'
    }),
    increaseStep () {
      return this.creationSteps + 1
    },
    getBack () {
      if (this.incommingItem === 'fromDashboard') {
        this.creationSteps = 0
        this.selectionTarget = null
        this.selectedExistingDocument = []
        this.selectedExistingTemplate = []
      } else {
        this.creationSteps = 1
        this.selectedExistingDocument = []
        this.selectedExistingTemplate = []
      }
      this.selected = null
      return this.creationSteps
    },
    handleIncommingDocument (document) {
      this.selectedExistingDocument = document
      this.creationSteps = this.creationSteps + 2
    },
    handleIncommingTemplate (template) {
      this.selectedExistingTemplate = template
      this.creationSteps = 5
    },
    triggerCancelSelection () {
      this.cancelSelection = true
      this.selectedExistingDocument = []
      return this.cancelSelection
    },
    triggerCancelSelectionTemp () {
      this.cancelSelectionInTemp = true
      this.selectedExistingTemplate = []
      return this.cancelSelectionInTemp
    },
    onAddTemplateEvent () {
      if (this.incommingItem) {
        if (this.incommingItem.isCompanyTab) {
          this.selectionTarget = this.incommingItem.company.companyData.companyName
          this.creationSteps = 1
        }
        if (this.incommingItem.isGroupTab) {
          this.selectionTarget = this.incommingItem.group.groupName
          this.creationSteps = 1
        }
        if (this.incommingItem.isMyTab) {
          this.selectionTarget = 'My templates'
          this.creationSteps = 1
        }
        if (this.incommingItem.isLavviraTab) {
          this.selectionTarget = 'Lavvira templates'
          this.creationSteps = 1
        }
      }
    },
    remove (item) {
      this.description.splice(this.description.indexOf(item), 1)
      this.description = [...this.description]
    },
    resetForm () {
      this.name = null
      // this.language = null
      this.description = ''
      this.selectedCategory = ''
    },
    reset () {
      this.disabled = true
      this.disableContinueButtonDocs = true
      this.disableContinueButtonTemplate = true
      this.disableContinueButtonUploadedDoc = true
      this.cancelSelection = false
      this.cancelSelectionInTemp = false
      this.selected = null
      this.name = null
      this.loading = false
      this.error = null
      this.description = ''
      this.templateDocument = []
      this.selectedCategory = ''
      this.selectedExistingDocument = []
      this.selectedExistingTemplate = []
      this.icon = mdiTextBoxOutline
      this.hoverCloseButtonIcon = false
      this.incommingItem = {}
      this.fromDashboard = false
      this.selectionTarget = null
      this.showArea = true
      this.triggerCancelSelection()
      this.triggerCancelSelectionTemp()
      this.handleIncommingDocument([])
      this.handleIncommingTemplate([])
      this.resetForm()
      this.creationSteps = 0
      this.$v.$reset()
    },
    getInfo (docId) {
      let notification = new Promise((resolve, reject) => {
        // let id
        // if (this.incommingItem && this.incommingItem.isCompanyTab) {
        //   id = this.incommingItem.company._id
        // } else if (this.incommingItem && this.incommingItem.isGroupTab) {
        //   id = this.incommingItem.group._id
        // } else if (this.incommingItem && this.incommingItem.isMyTab) {
        //   id = this.account._id
        // }
        try {
          const connection = new autobahn.Connection({
            url: process.env.VUE_APP_CROSSBAR_PATH,
            realm: process.env.VUE_APP_CROSSBAR_REALM
          })
          connection.onopen = function (session) {
          // call a remote procedure
            function onevent (args) {
              if (args) {
                resolve(args)
              }
            }
            session.subscribe(`lavvira.files.${docId}`, (...args) => {
              onevent(args)
            })
          }

          connection.onclose = function (reason, details) {
          // handle connection lost
            console.log(reason, details, 'connection.onclose')
          }
          connection.open()
        } catch (e) {
          console.error(e, 'ERROR in autobahn connection')
        }
      })
      return notification
    },
    async onEventFinish (result, transferedName, transferedCategory, transferedDescription, incommingItem) {
      let payload = {}
      if (incommingItem && incommingItem.company) {
        payload = {
          name: transferedName,
          category: transferedCategory,
          description: transferedDescription,
          templateData: {
            json: result.parsed.text_json_labels,
            html: result.parsed.text_xml_labels
          },
          companyId: this.incommingItem.company._id
        }
        try {
          this.addCompanyTemplate(payload)
          this.dialog = false
          this.reset()
          this.deleteCompanySceleton()
        } catch (e) {
          this.error = e
          console.error(this.error, 'error')
        } finally {
          this.loading = false
        }
      } else if (incommingItem && incommingItem.group) {
        payload = {
          name: transferedName,
          category: transferedCategory,
          description: transferedDescription,
          // language: transferedLanguage,
          templateData: {
            json: result.parsed.text_json_labels,
            html: result.parsed.text_xml_labels
          },
          groupId: this.incommingItem.group._id
        }
        try {
          this.addGroupTemplate(payload)
          this.dialog = false
          this.reset()
          this.deleteGroupSceleton()
        } catch (e) {
          this.error = e
          console.error(this.error, 'error')
        } finally {
          this.loading = false
        }
      } else if (incommingItem && incommingItem.accountId && incommingItem.accountId.length && !incommingItem.isLavviraTab) {
        payload = {
          name: transferedName,
          category: transferedCategory,
          description: transferedDescription,
          templateData: {
            json: result.parsed.text_json_labels,
            html: result.parsed.text_xml_labels
          },
          accountId: incommingItem.accountId
        }
        try {
          this.addTemplate(payload)
          this.dialog = false
          this.reset()
          this.deleteSceleton()
        } catch (e) {
          this.error = e
          console.error(this.error, 'error')
        } finally {
          this.loading = false
        }
      } else if (incommingItem && incommingItem.isLavviraTab) {
        payload = {
          name: transferedName,
          category: transferedCategory,
          description: transferedDescription,
          // language: transferedLanguage,
          templateData: {
            json: result.parsed.text_json_labels,
            html: result.parsed.text_xml_labels
          },
          accountId: this.incommingItem.accountId,
          shared: true
        }
        try {
          this.addTemplate(payload)
          this.dialog = false
          this.reset()
          this.deleteSceleton()
        } catch (e) {
          this.error = e
          console.error(this.error, 'error')
        } finally {
          this.loading = false
        }
      }
    },
    async submit () {
      // This function handles the four template creation options.
      this.$v.$touch()
      if (this.$v.$error) return false
      this.loading = true
      let docId

      // before starting the operation, we first secure ourselves and find the company and the groups, if available,
      // as the dialogue can be opened from the templates section, but also from the dashboard.
      // In the second case, we do not have the such important incomingItem and somehow we need to understand where the template will be saved,
      // namely whether in my templates, in those of the company or in a group.
      // for this reason we modify the incomingItem as if we were in the template section itself, not on the dashboard
      if (this.incommingItem === 'fromDashboard') {
        if (this.selectionTarget === 'My templates') {
          this.incommingItem = {
            accountId: this.account._id,
            isMyTab: true
          }
        } else if (this.company && this.selectionTarget.includes(this.company.companyData.companyName)) {
          this.incommingItem = {
            company: this.company,
            isCompanyTab: true
          }
        } else {
          for (let i = 0; i < this.company.groups.length; i++) {
            const group = this.company.groups[i]
            if (this.selectionTarget.includes(group.groupName)) {
              this.incommingItem = {
                group: group,
                isGroupTab: true
              }
            }
          }
        }
      }

      if (this.templateDocument && this.templateDocument.length) {
        // The first and most important check handles the creation of a template from a document, which includes uploading a file
        // We first check whether the template should be created in a group, company or in the field my templates
        if (this.incommingItem && this.incommingItem.company && this.incommingItem.isCompanyTab) {
          const _id = this.account.companyId
          const payload = {
            language: this.templateDocument[0].language,
            file: this.templateDocument[0].file,
            isCompanyTab: true
          }
          // Then we upload the document to the company
          const doc = await this.uploadCompanyDocument({ _id, payload })
          docId = doc.data._id
          // Then we push a sceleton loading card in to the company templates in the store
          // so that the user can see that the template is already being processed
          const sceleton = {
            name: 'loading',
            isSceleton: true
          }
          this.setCompanySceletonCard({ sceleton })
        }
        if (this.incommingItem && this.incommingItem.group && this.incommingItem.isGroupTab) {
          // the group document has to be uploaded with company id because the folder in which it is uploaded is marked with this id
          // the group id is in the payload and the backend handels it, so the uploaded doc is landing in the group docs
          const _id = this.account.companyId
          const payload = {
            language: this.templateDocument[0].language,
            file: this.templateDocument[0].file,
            isGroupTab: true,
            groupId: this.incommingItem.group._id
          }
          // or we upload the document to the group
          const doc = await this.uploadGroupDocument({ _id, payload })
          docId = doc.data._id
          // Then we push a sceleton loading card in to the group templates in the store
          // so that the user can see that the template is already being processed
          const sceleton = {
            name: 'loading',
            isSceleton: true,
            sharedWith: {
              groups: [this.incommingItem.group]
            }
          }
          this.setGroupSceletonCard({ sceleton })
        }
        if (this.incommingItem && (this.incommingItem.isMyTab || this.incommingItem.isLavviraTab)) {
          const _id = this.account._id
          const payload = {
            language: this.templateDocument[0].language,
            file: this.templateDocument[0].file,
            isMyTab: true
          }
          // or we upload the document to my documents
          const doc = await this.uploadDocument({ _id, payload })
          docId = doc.data._id
          // Then we push a sceleton loading card in to the templates in the store
          // so that the user can see that the template is already being processed
          const sceleton = {
            name: 'loading',
            isSceleton: true
          }
          this.setSceletonCard({ sceleton })
        }
        // Since in closed dialog we lose the available data from the data section,
        // we have to transfer them to the next function,
        // which will continue to be performed after closing.
        const transferName = this.name
        const transferCategory = this.selectedCategory
        const transferDescription = this.description
        const incommingItemCopy = this.incommingItem
        // const transferLanguage = this.language
        this.loading = false
        this.dialog = false
        // In this step we open a web socket connection to crossbar
        // and wait for the result of processing the document (getInfo)
        // dialog is now closed and sceleton card loaded
        let result = await this.getInfo(docId)
        // After processing the document
        // we receive the ID of the document inside of the result and send a request to get it
        // In the next step we create the new template from the already processed document (OnEventFinish)
        await this.onEventFinish(result[0][0], transferName, transferCategory, transferDescription, incommingItemCopy)
        return
      }
      if (this.selectedExistingDocument && this.selectedExistingDocument.length) {
        // in this step we create a template
        // from an already available processed document from the documents section
        let payload = {}
        if (this.incommingItem && this.incommingItem.company && this.incommingItem.isCompanyTab) {
          payload = {
            name: this.name,
            category: this.selectedCategory,
            description: this.description,
            // language: this.language,
            templateData: {
              json: this.selectedExistingDocument[0].parsed.text_json_labels,
              html: this.selectedExistingDocument[0].parsed.text_xml_labels
            },
            companyId: this.incommingItem.company._id
          }
          try {
            await this.addCompanyTemplate(payload)
            this.dialog = false
            this.reset()
          } catch (e) {
            this.error = e
            console.error(this.error, 'error')
          } finally {
            this.loading = false
          }
        } else if (this.incommingItem && this.incommingItem.group && this.incommingItem.isGroupTab) {
          payload = {
            name: this.name,
            category: this.selectedCategory,
            description: this.description,
            // language: this.language,
            templateData: {
              json: this.selectedExistingDocument[0].parsed.text_json_labels,
              html: this.selectedExistingDocument[0].parsed.text_xml_labels
            },
            groupId: this.incommingItem.group._id
          }
          try {
            await this.addGroupTemplate(payload)
            this.dialog = false
            this.reset()
          } catch (e) {
            this.error = e
            console.error(this.error, 'error')
          } finally {
            this.loading = false
          }
        } else if (this.incommingItem && this.incommingItem.accountId && this.incommingItem.isMyTab) {
          payload = {
            name: this.name,
            category: this.selectedCategory,
            description: this.description,
            // language: this.language,
            templateData: {
              json: this.selectedExistingDocument[0].parsed.text_json_labels,
              html: this.selectedExistingDocument[0].parsed.text_xml_labels
            },
            accountId: this.incommingItem.accountId
          }
          try {
            await this.addTemplate(payload)
            this.dialog = false
            this.reset()
          } catch (e) {
            this.error = e
            console.error(this.error, 'error 1215')
          } finally {
            this.loading = false
          }
        }
      }
      if (this.selectedExistingTemplate && this.selectedExistingTemplate.length) {
        // in this step we create a template from a current template
        // so that the current template can be modified without changing the original
        let payload
        if (this.incommingItem && this.incommingItem.company && this.incommingItem.isCompanyTab) {
          payload = {
            name: this.name,
            category: this.selectedCategory,
            description: this.description,
            // language: this.language,
            templateData: this.selectedExistingTemplate[0].templateData,
            companyId: this.incommingItem.company._id
          }
          try {
            await this.addCompanyTemplate(payload)
            this.dialog = false
            this.reset()
          } catch (e) {
            this.error = e
            console.error(this.error, 'error')
          } finally {
            this.loading = false
          }
        } else if (this.incommingItem && this.incommingItem.group && this.incommingItem.isGroupTab) {
          payload = {
            name: this.name,
            category: this.selectedCategory,
            description: this.description,
            // language: this.language,
            templateData: this.selectedExistingTemplate[0].templateData,
            groupId: this.incommingItem.group._id
          }
          try {
            await this.addGroupTemplate(payload)
            this.dialog = false
            this.reset()
          } catch (e) {
            this.error = e
            console.error(this.error, 'error')
          } finally {
            this.loading = false
          }
        } else if (this.incommingItem && this.incommingItem.accountId && this.incommingItem.isMyTab) {
          payload = {
            name: this.name,
            category: this.selectedCategory,
            description: this.description,
            // language: this.language,
            templateData: this.selectedExistingTemplate[0].templateData,
            accountId: this.incommingItem.accountId
          }
          try {
            await this.addTemplate(payload)
            this.dialog = false
            this.reset()
          } catch (e) {
            this.error = e
            console.error(this.error, 'error')
          } finally {
            this.loading = false
          }
        }
      }
      if (!this.templateDocument.length && !this.selectedExistingDocument.length && !this.selectedExistingTemplate.length) {
        // in this step we create a blank template
        let payload
        if (this.incommingItem && this.incommingItem.company && this.incommingItem.isCompanyTab) {
          payload = {
            name: this.name,
            category: this.selectedCategory,
            description: this.description,
            templateData: {
              json: {},
              html: ''
            },
            companyId: this.incommingItem.company._id
          }
          try {
            await this.addCompanyTemplate(payload)
            this.dialog = false
            this.reset()
          } catch (e) {
            this.error = e
            console.error(this.error, 'error')
          } finally {
            this.loading = false
          }
        } else if (this.incommingItem && this.incommingItem.group && this.incommingItem.isGroupTab) {
          payload = {
            name: this.name,
            category: this.selectedCategory,
            description: this.description,
            templateData: {
              json: {},
              html: ''
            },
            groupId: this.incommingItem.group._id
          }
          try {
            await this.addGroupTemplate(payload)
            this.dialog = false
            this.reset()
          } catch (e) {
            this.error = e
            console.error(this.error, 'error')
          } finally {
            this.loading = false
          }
        } else if (this.incommingItem && this.incommingItem.accountId && this.incommingItem.isMyTab) {
          payload = {
            name: this.name,
            category: this.selectedCategory,
            description: this.description,
            templateData: {
              json: {},
              html: ''
            },
            accountId: this.incommingItem.accountId
          }
          try {
            await this.addTemplate(payload)
            this.dialog = false
            this.reset()
          } catch (e) {
            this.error = e
            console.error(this.error, 'error')
          } finally {
            this.loading = false
          }
        } else if (this.incommingItem && this.incommingItem.isLavviraTab) {
          payload = {
            name: this.name,
            category: this.selectedCategory,
            description: this.description,
            // language: this.language,
            templateData: {
              json: {},
              html: ''
            }
          }
          try {
            await this.addTemplate(payload)
            this.dialog = false
            this.reset()
          } catch (e) {
            this.error = e
            console.error(this.error, 'error')
          } finally {
            this.loading = false
          }
        }
      }
    }
  }
}
</script>

<style scoped>
  .add-template-toolbar {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 50;
  }
  .toolbar {
    position: sticky;
    top: 48px;
    z-index: 1;
  }
</style>
